import React from 'react'

const Footer = (props) =>
      <footer>
        <a href="https://twitter.com/PyConHiro">Twitter</a> |
        お問い合わせ hiroshima@pycon.jp |
        Copyright © PyCon mini Hiroshima 2020
      </footer>

export default Footer
