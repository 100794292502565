import React from 'react'

const isSMILAvailable = () => {
  if (typeof window === 'undefined') {
    return true;
  } else {
    return window.navigator.userAgent.match(/(MSIE|Trident|Edge)\//) === null;
  }
}

const RegistrationButton = ({children}) =>
      <></>

const disabledRegistrationButton = ({children}) =>
      <a href="https://pycon-hiroshima.connpass.com/event/186849/">
        <span className="registration-button">
          <span className={`registration-inner ${isSMILAvailable() ? 'registration-inner-animation' : ''}`}>
            ⧹⧹ 参加登録はこちら ⧸⧸
          </span>
        </span>
      </a>

export default RegistrationButton
