import React from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'

function Block(props) {
  return (
    <div className={`block ${props.backgroundClass}`}>
      { props.title &&
        <h2 className="">
          {props.title}
        </h2>
      }
      { props.subtitle &&
        <h3 className="">
          {props.subtitle}
        </h3>
      }
      <div>
        {props.children}
      </div>
    </div>
  )
}

Block.propTypes = {
  backgroundClass: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

Block.defaultProps = {
  backgroundClass: '',
  title: '',
  subtitle: '',
}

export default Block
