import React from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'

function Menu(props) {
  return (
    <div className={`${props.backgroundClass}`}>
      <nav className="main-nav">
        <ul className="list">
          <li><Link href='/'><a>HOME</a></Link></li>
          <li><a href="https://pycon-hiroshima.connpass.com/event/186849/">参加登録</a></li>
          <li><Link href='/schedule'><a>スケジュール</a></Link></li>
          <li><Link href='/goodies'><a>Goodies</a></Link></li>
          <li><Link href='/policy'><a>行動規範</a></Link></li>
        </ul>
      </nav>
    </div>
  )
}

export default Menu
